import en from './en';
import lt from './lt';
import lv from './lv';
import ee from './ee';
import fi from './fi';
import ro from './ro';
import sk from './sk';
import dk from './dk';
import se from './se';
import pl from './pl';
import de from './de';
import rs from './rs';
import cz from './cz';
import bg from './bg';
import mk from './mk';
import si from './si';
import ba from './ba';
import ar from './ar';

export default {
  en,
  lt,
  lv,
  ee,
  fi,
  ro,
  sk,
  dk,
  se,
  pl,
  de,
  rs,
  cz,
  bg,
  mk,
  si,
  ba,
  ar
};
